<template>
  <div class="p-2">
    <div class="w-100 mt-2 text-center" style="position: relative">
      <b-img :src="imageUrl" class="w-100 h-100" fluid></b-img>
      <!--上半行-->
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 20%; left: 15%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 34%; left: 1.2%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 34%; left: 7%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 34%; left: 12.5%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 34%; left: 18.3%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 22.2%; left: 24%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 20%; left: 33.7%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.191']?'run':'alarm')"
           style="position: absolute; top: 22.2%; left: 40%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 20%; left: 59.6%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 20%; left: 62.2%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 20%; left: 64.2%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.189.189.189']?'run':'alarm')"
           style="position: absolute; top: 20%; left: 65.1%"></div>
      <!--下半行-->
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.188.5.23']?'run':'alarm')"
           style="position: absolute; top: 60%; left: 9%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.7.41']?'run':'alarm')"
           style="position: absolute; top: 60%; left: 20%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.5.41']?'run':'alarm')"
           style="position: absolute; top: 60%; left: 30.4%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.12.99']?'run':'alarm')"
           style="position: absolute; top: 60%; left: 40.3%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.12.99']?'run':'alarm')"
           style="position: absolute; top: 60%; left: 50%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.12.99']?'run':'alarm')"
           style="position: absolute; top: 60%; left: 61.5%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.11.213']?'run':'alarm')"
           style="position: absolute; top: 60%; left: 73%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.11.203']?'run':'alarm')"
           style="position: absolute; top: 60%; left: 85.1%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.11.201']?'run':'alarm')"
           style="position: absolute; top: 72.6%; left: 94%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.11.202']?'run':'alarm')"
           style="position: absolute; top: 77.4%; left: 94%"></div>
      <div class="signalDot" :class="'signalDot_'+(server_ip_list['192.168.11.203']?'run':'alarm')"
           style="position: absolute; top: 82%; left: 94%"></div>
    </div>
  </div>
</template>

<script>
import {getServerOnlineStatus} from '@/api/jsf35'
import {onMounted, ref} from "@vue/composition-api";
import {showToast} from "@/libs/utils/showToast";

export default {
  name: "SystemNetworkTopology",
  setup() {
    const imageUrl = require("@/assets/images/jsf35/system-network-topology.png");
    const server_ip_list = ref({
      "192.189.189.189": false,
      "192.168.12.99": false,
      "192.168.7.40": false,
      "192.168.5.24": false,
      "192.168.0.125": false,
      "192.168.1.99": false,
      "192.168.6.239": false,
      "192.16.0.211": false,
      "192.189.189.191": false,
      "192.188.5.23": false,
      "192.168.7.41": false,
      "192.168.5.41": false,
      "192.168.11.213": false,
      "192.168.11.203": false,
      "192.168.11.201": false,
      "192.168.11.202": false
    })
    //获取服务器在线状态
    const fetchServerOnlineStatus = () => {
      getServerOnlineStatus().then(res => {
        server_ip_list.value = res.data
      }).catch(error => {
        showToast(error)
      })
    }

    onMounted(() => {
      fetchServerOnlineStatus()
    })
    return {
      imageUrl,
      server_ip_list
    };
  },
};
</script>

<style lang="scss" scoped>
.signalDot {
  width: 0;
  height: 0;
  background: rgba(79, 177, 230, 0.5);
  //border: 1px solid rgba(79, 177, 230, 1);
  border-radius: 0.5rem;
  color: white;

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgb(79, 177, 230);
    position: absolute;
    top: -5px;
    right: -15px;
    animation: color 1s infinite linear;
  }

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: rgb(79, 177, 230);
    position: absolute;
  }

  //animation: rota 4s infinite ease;
}

.signalDot_alarm::before,
.signalDot_alarm::after {
  background: rgb(234, 84, 85);
}

.signalDot_run::before,
.signalDot_run::after {
  background: rgb(40, 199, 111);
}


@keyframes color {
  from {
    opacity: 0.01;
  }

  to {
    opacity: 0.5;
  }
}
</style>